import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createNewConnectionAPI } from '../../api/bookings/createNewConnection';
import { deleteConnectionByIdApi } from '../../api/bookings/deleteConnectionApi';
import { getConnectionsByOrg } from '../../api/bookings/getConnections';
import { getPortalsByOrg } from '../../api/getPortals';
import { updateConnectionAPI } from '../../api/bookings/updateConnection';
import * as Sentry from '@sentry/react';
import { ToastProps } from '../../components/toast/ToastModel';
import { getEmailHistoryAPI } from '../../api/bookings/getEmailHistory';
export interface IConnection {
  id: string;
  connectionName: string;
  date: string;
  startTime: string;
  duration: number;
  portals: string[];
  url?: string;
  guests: {
    email: string;
    responseStatus: string;
    portal_id: string;
    portal_label: string;
    userSessionURL?: string;
  }[];
  invitedPortalIds: string[];
  slotTime: string;
  sessionId: string;
  eventId?: string;
  connectionDescription: string;
  guestsCanInviteOthers: boolean;
  autoRecord: boolean;
  organizer?: { name: string; email: string };
}

interface ConnectionState {
  allConnections: { data: IConnection[]; pager: any };
  loading: boolean;
  error: boolean;
  errorObj: any;
  selectedConnId: null | string;
  selectedConnection: IConnection | null;
  isConnectionModalOpen: boolean;
  portals: any;
  toast: ToastProps;
  emails: any,
  searchLoading: boolean
}

interface EmailHistoryParams {
  orgId: string;
  user: string;
  search: string;
}


const initialState: ConnectionState = {
  allConnections: { data: [], pager: {} },
  loading: false,
  error: false,
  errorObj: null,
  selectedConnId: null,
  selectedConnection: null,
  isConnectionModalOpen: false,
  portals: [],
  toast: { type: '', message: '', msgHeader: '', show: false },
  emails: [],
  searchLoading: false
};

export const createConection: any = createAsyncThunk(
  'connection/createConnections',
  async (body: any, { rejectWithValue }) => {
    try {
      const { sessionId, data } = body;
      sessionId ? await updateConnectionAPI(sessionId, data) : await createNewConnectionAPI(data);
      return getConnections(data.org_id);
    } catch (error: any) {
      return rejectWithValue(error.response);
      // Sentry.captureException(error);
    }
  },
);


export const getEmailsHistory = createAsyncThunk(
  'connection/emailHistory',
  async ({ orgId, user, search }: EmailHistoryParams, { rejectWithValue }) => {
    try {
      return await getEmailHistoryAPI(orgId, user, search);
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllConnections: any = createAsyncThunk('connection/getAllConnections', async (orgId: string) => {
  try {
    return getConnections(orgId);
  } catch (error) {
    Sentry.captureException(error);
  }
});

export const deleteConnectionById = createAsyncThunk(
  'connection/updateConnectionById',
  async (body: { sessionId: string; message: string, org_id: string }) => {
    try {
      await deleteConnectionByIdApi(body.sessionId, body.message, body.org_id);
      return body.sessionId;
    } catch (error) {
      Sentry.captureException(error);
    }
  },
);

export const getPortalsByorgId: any = createAsyncThunk('connection/getPortalsByorgId', async (orgId: string) => {
  try {
    return await getPortalsByOrg(orgId);
  } catch (error) {
    Sentry.captureException(error);
  }
});

const getConnections = async (orgId: string) => {
  return (await getConnectionsByOrg(orgId))?.data?.result ?? [];
};

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setSelectedConnId: (state, action) => {
      state.selectedConnId = action.payload;
    },
    toggleConnectionModal: (state) => ({
      ...state,
      isConnectionModalOpen: !state.isConnectionModalOpen,
    }),
    openConnectionModal: (state, { payload }) => ({
      ...state,
      isConnectionModalOpen: true,
      selectedConnId: payload ?? null,
    }),
    closeConnectionModal: (state) => ({
      ...state,
      isConnectionModalOpen: false,
      selectedConnId: null,
    }),
    setSelectedConnection: (state, { payload }) => ({
      ...state,
      selectedConnection: payload ?? null,
    }),
    setToast: (state, { payload }) => ({
      ...state,
      toast: payload,
    }),
    setAllConnections: (state, { payload }) => ({
      ...state,
      allConnections: { data: payload, pager: {} },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllConnections.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllConnections.fulfilled, (state, action) => {
        state.allConnections = { data: action.payload, pager: {} };
        state.loading = false;
      })
      .addCase(getAllConnections.rejected, (state, action) => {
        state.allConnections = { data: [], pager: {} };
        state.error = true;
        state.loading = false;
        state.errorObj = action.payload;
      })
      .addCase(getPortalsByorgId.pending, () => {
        // console.log('state', state);
      })
      .addCase(getPortalsByorgId.fulfilled, (state, action) => {
        if (action.payload?.data?.data) {
          state.portals = action.payload.data.data;
        }
      })
      .addCase(getPortalsByorgId.rejected, () => {
        // console.log('state', state);
      })
      .addCase(createConection.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createConection.fulfilled, (state, action) => {
        state.allConnections = { data: action.payload, pager: {} };
        state.loading = false;
      })
      .addCase(createConection.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.errorObj = action.payload;
      })
      .addCase(getEmailsHistory.pending, (state) => {
        state.searchLoading = true;
        state.error = false;
      })
      .addCase(getEmailsHistory.fulfilled, (state, action) => {
        state.emails = action.payload?.data?.bookingEmails
        state.searchLoading = false;
      })
      .addCase(getEmailsHistory.rejected, (state, action) => {
        state.errorObj = action.payload;
        state.searchLoading = false;
        state.error = true;
      })
      .addCase(deleteConnectionById.fulfilled, (state, action) => {
        state.allConnections = {
          data: state.allConnections.data.filter((x) => x.sessionId !== action.payload),
          pager: {},
        };
        state.loading = false;
        state.error = false;
      })
      .addCase(deleteConnectionById.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorObj = action.payload;
      });
  },
});

export const {
  setSelectedConnId,
  toggleConnectionModal,
  openConnectionModal,
  closeConnectionModal,
  setSelectedConnection,
  setToast,
  setAllConnections,
} = connectionSlice.actions;
export default connectionSlice.reducer;
