import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllOrganizationsAPI } from '../../api/getAllOrganizationsAPI';
import { getOrganizationByOrgIdAPI } from '../../api/getOrganizationByOrgIdAPI';
import { updateOrganizationByOrgIdAPI } from '../../api/updateOrganizationByOrgIdAPI';
import { OrganizationModel } from '../model/organizationModel';
import * as Sentry from '@sentry/react';

const initialState = { allOrganization: { data: <OrganizationModel[]>[], pager: {} }, loading: false, error: false, errorObj: <any>{}, selectedOrgId: '', organization: <OrganizationModel>{}, updatedOrganization: <OrganizationModel>{} }

export const getAllOrganizations: any = createAsyncThunk('organization/getAllOrganizations', async (limit: number) => {
    try {
        const response = await getAllOrganizationsAPI(limit);
        return response.data;
    } catch (error) {
        Sentry.captureException(error);
    }
});

export const getOrganizationByOrgId = createAsyncThunk('organization/getOrganizationByOrgId', async (orgId: string) => {
    try {
        return await getOrganizationByOrgIdAPI(orgId);
    } catch (error) {
        Sentry.captureException(error);
    }
});

export const updateOrganizationByOrgId = createAsyncThunk('organization/updateOrganizationByOrgId', async (org: any) => {
    delete org.updatedOrg.pk;
    delete org.updatedOrg.sk;
    delete org.updatedOrg.org_id;
    delete org.updatedOrg.updated_on;
    return await updateOrganizationByOrgIdAPI(org);
});


const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setSelectedOrganizationId: (state, action) => {
            state.selectedOrgId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrganizations.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(getAllOrganizations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                const newOrgList = {
                    data: <OrganizationModel[]>[],
                    pager: action.payload.pager
                }
                action.payload.data.forEach((item: any) => {
                    if (!(item.org_id.includes("-bkp"))) {
                        newOrgList.data.push(item)
                    }
                });

                state.allOrganization = newOrgList;
            })
            .addCase(getAllOrganizations.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.allOrganization = { data: <OrganizationModel[]>[], pager: {} }
            })
            .addCase(getOrganizationByOrgId.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.updatedOrganization = {} as OrganizationModel;
            })
            .addCase(getOrganizationByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.organization = <OrganizationModel>action.payload.data;
                    state.updatedOrganization = {} as OrganizationModel;
                }
                else {
                    state.organization = <OrganizationModel>{};
                    state.updatedOrganization = {} as OrganizationModel;
                    state.error = true;
                }
            })
            .addCase(getOrganizationByOrgId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.updatedOrganization = {} as OrganizationModel;
            })
            .addCase(updateOrganizationByOrgId.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateOrganizationByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload["data"]) {
                    state.updatedOrganization = <OrganizationModel>action.payload.data;
                }
                else {
                    state.updatedOrganization = <OrganizationModel>{};
                    state.error = true;
                }
            })
            .addCase(updateOrganizationByOrgId.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            })
        // You can chain calls, or have separate `builder.addCase()` lines each time
        /* .addCase(decrement, (state, action) => {}) */
        // You can match a range of action types
        /* .addMatcher(
          isRejectedAction,
          // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
          (state, action) => {}
        ) */
        // and provide a default case if no other handlers matched
        /* .addDefaultCase((state, action) => {}) */
    },
})

export const { setSelectedOrganizationId } = organizationSlice.actions
export default organizationSlice.reducer;